import { Col, Row } from 'antd';
import React from 'react';
import BadgeCounter from '../BadgeCounter';
import clsx from 'clsx';
const RoomItem = ({ isOnline = false, avatar = null, name = null, lastMessage = null, lastMessageAt = null, unreadCount = 0, onClick = () => { }, isActive = false, }) => {
    return (React.createElement("div", { className: clsx([
            'p-3 cursor-pointer relative',
            isActive ? 'bg-theme-primary-main bg-opacity-20 rounded-lg' : '',
        ]), onClick: onClick },
        React.createElement(Row, { gutter: [24, 24], wrap: false },
            React.createElement(Col, { flex: "none" },
                React.createElement("div", { className: "w-[50px] h-[50px] rounded-lg overflow-hidden bg-gray-300 border" }, avatar && (React.createElement("img", { src: avatar, alt: "avatar", className: "w-full h-full object-center object-cover" })))),
            React.createElement(Col, { flex: "auto" },
                React.createElement("div", { className: "flex-1 flex flex-col gap-1 pr-[40px]" },
                    React.createElement("div", { className: "font-semibold truncate" }, name),
                    !!lastMessage && React.createElement("div", { className: "text-sm text-gray-500 truncate" }, lastMessage),
                    !!lastMessageAt && (React.createElement("div", { className: "text-xs text-gray-400 truncate" }, lastMessageAt))))),
        unreadCount > 0 && (React.createElement(BadgeCounter, { count: unreadCount, style: {
                position: 'absolute',
                bottom: '10px',
                right: '10px',
            } }))));
};
export default RoomItem;
