import React, { useCallback } from 'react';
import { MESSAGE_TYPE, } from './Chat.interfaces';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import { Col, Row } from 'antd';
const MessageItemPreview = ({ type, meta, onClick = null }) => {
    const handleClick = useCallback(() => {
        if (onClick) {
            return onClick(type, meta);
        }
    }, [onClick]);
    if (type === MESSAGE_TYPE.PRODUCT_RETAIL) {
        const { id, name, price, location, imageUrl, link } = meta;
        return (React.createElement("div", { onClick: handleClick, className: "flex gap-2 cursor-pointer", role: "button", tabIndex: 0 },
            React.createElement(Row, { wrap: false, gutter: [12, 12] },
                React.createElement(Col, { flex: "none" },
                    React.createElement("div", { className: "w-[50px] h-[50px] rounded-lg overflow-hidden bg-gray-300" },
                        React.createElement("img", { src: imageUrl, alt: "", className: "w-full h-full object-center object-cover" }))),
                React.createElement(Col, { flex: "auto" },
                    React.createElement("div", { className: "flex flex-col pt-1" },
                        React.createElement("div", { className: "truncate" }, name),
                        React.createElement("div", { className: "text-sm text-theme-primary-main font-semibold" }, price),
                        !!location && (React.createElement("div", { className: "flex gap-2" },
                            React.createElement(LocationMarkerIcon, { height: 20, width: 20, color: "#55555" }),
                            React.createElement("div", { className: "text-sm truncate" }, location))))))));
    }
    if (type === MESSAGE_TYPE.PO) {
        const { code, productName, price, imageUrl } = meta;
        return (React.createElement("div", { onClick: handleClick, className: "", role: "button", tabIndex: 0 },
            React.createElement(Row, { wrap: false, gutter: [12, 12] },
                React.createElement(Col, { flex: "none" },
                    React.createElement("div", { className: "w-[50px] h-[50px] rounded-lg overflow-hidden bg-gray-300" },
                        React.createElement("img", { src: imageUrl, alt: "", className: "w-full h-full object-center object-cover" }))),
                React.createElement(Col, { flex: "auto" },
                    React.createElement("div", { className: "flex flex-col pt-1 gap-1" },
                        React.createElement("div", { className: "w-full p-1 bg-theme-primary-main font-semibold text-white" }, code),
                        React.createElement("div", { className: "truncate" }, productName),
                        React.createElement("div", { className: "text-sm text-theme-primary-main font-semibold" }, price))))));
    }
    return React.createElement(React.Fragment, null);
};
export default MessageItemPreview;
