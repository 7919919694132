export const isJsonString = (str = '') => {
    let value = str;
    try {
        value = JSON.parse(str);
        return typeof value === 'object' && value !== null;
    }
    catch (e) {
        return false;
    }
};
