import React from 'react';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import PropTypes from 'prop-types';
const CenterMarkerAbsolute = ({ isDragging }) => {
    return (React.createElement("div", { style: {
            position: 'absolute',
            top: '50%',
            bottom: '50%',
            left: '50%',
            right: '50%',
        }, className: clsx([
            'text-theme-primary-main w-[40px] h-[40px] flex items-center justify-center rounded-full  transition-all ease-in-out',
            isDragging && 'opacity-70 scale-90',
        ]) },
        React.createElement("div", { className: "absolute", style: { marginBottom: 20, marginLeft: -40 } },
            React.createElement(LocationMarkerIcon, { width: 40, height: 40, color: "currentcolor" }),
            React.createElement("div", { className: clsx(['block bg-theme-primary-lighter absolute left-0 right-0 mx-auto']), style: {
                    zIndex: 20,
                    height: 8,
                    width: 20,
                    borderRadius: '50%',
                } }))));
};
CenterMarkerAbsolute.propTypes = {
    isDragging: PropTypes.bool,
};
CenterMarkerAbsolute.defaultProps = {
    isDragging: false,
};
export default CenterMarkerAbsolute;
