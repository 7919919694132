import React from 'react';
import { Upload } from 'antd';
import { CloudUploadIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import DefaultItemRenderComponent from './ItemRender';
const UploadDragger = ({ placeholder = '', maxCount, fileList, itemRender, ...restProps }) => {
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    const defaultItemRender = (_originNode, file, _fileList, action) => {
        const fileObject = typeof file === 'string'
            ? {
                url: file,
                name: file,
                size: 0,
                type: 'image/',
            }
            : {
                ...file,
                url: URL.createObjectURL(file.originFileObj),
            };
        return React.createElement(DefaultItemRenderComponent, { fileObject: fileObject, action: action });
    };
    return (React.createElement("div", { className: clsx(['upload-dragger-wrapper', fileList?.length >= maxCount ? 'max-count' : '']) },
        React.createElement(Upload.Dragger, { fileList: fileList, itemRender: itemRender ? itemRender : defaultItemRender, onPreview: onPreview, maxCount: maxCount, ...restProps },
            React.createElement("div", { className: "py-4" },
                React.createElement("div", { className: "flex items-center justify-center w-full" },
                    React.createElement(CloudUploadIcon, { height: 46, width: 46, className: "fill-theme-primary-main" })),
                React.createElement("div", null, placeholder ?? 'Upload file')))));
};
export default UploadDragger;
