import { TreeSelect as TreeSelectAntd } from 'antd';
import React, { useEffect, useRef } from 'react';
const TreeSelect = ({ value, placeholder, ...restProps }) => {
    const ref = useRef();
    useEffect(() => {
        if (ref.current && placeholder) {
            const selectionSearchWrapper = ref.current.querySelector('.ant-select-selection-search');
            const input = selectionSearchWrapper.querySelector('input');
            if (!value) {
                input.setAttribute('placeholder', placeholder);
            }
            else {
                input.setAttribute('placeholder', '');
            }
        }
    }, [value, ref, placeholder]);
    return (React.createElement("div", { ref: ref },
        React.createElement(TreeSelectAntd, { placeholder: placeholder, value: value, ...restProps })));
};
export default TreeSelect;
