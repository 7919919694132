import { Input } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import Button from '../Button';
import SendIcon from './SendIcon';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import { EmojiHappyIcon, ReplyIcon, XIcon } from '@heroicons/react/solid';
import MessageItemPreview from './MessageItemPreview';
const MAX_CHARACTERS = 1100;
const ComposeMessage = ({ value = '', suggestion = [], onChange, onClickSend = () => { }, isLoadingSend = false, }) => {
    const [showedEmoji, setShowedEmoji] = useState(false);
    const renderedValue = useMemo(() => {
        if (value === null) {
            return '';
        }
        if (typeof value === 'string') {
            return value || '';
        }
        if (typeof value === 'object') {
            return value?.text || '';
        }
        return '';
    }, [value]);
    const handleSend = useCallback(() => {
        onClickSend();
    }, [onClickSend]);
    const handleChange = useCallback((event) => {
        let currentValue = event.target.value;
        if (String(currentValue).length >= MAX_CHARACTERS) {
            return;
        }
        if (value === null || typeof value === 'string') {
            return onChange(currentValue);
        }
        if (typeof value === 'object') {
            return onChange({
                ...value,
                text: currentValue,
            });
        }
        return;
    }, [value, renderedValue]);
    const handleKeyDown = useCallback((event) => {
        let currentValue = value;
        if (event.keyCode === 13) {
            event.preventDefault();
        }
        if (event.keyCode === 13 && event.shiftKey) {
            currentValue = `${currentValue ?? ''}\n`;
            handleChange({ target: { value: currentValue } });
        }
        else if (event.keyCode === 13 && renderedValue.length > 0) {
            if (renderedValue.length > 0) {
                return handleSend();
            }
        }
    }, [value, renderedValue]);
    const handleClickEmoji = useCallback(({ emoji }) => {
        if (value === 'string') {
            handleChange({ target: { value: `${renderedValue}${emoji}` } });
        }
        else {
            handleChange({ target: { value: `${renderedValue}${emoji}` } });
        }
        setShowedEmoji(false);
    }, [renderedValue]);
    const handleClickSuggestion = useCallback((event) => {
        const message = event?.target?.innerText;
        handleChange({ target: { value: message } });
    }, [value, renderedValue]);
    const handleRemovePreview = useCallback(() => {
        const newValue = value.text;
        return onChange(newValue);
    }, [value]);
    const renderQuote = (quote) => {
        if (quote !== null && typeof quote === 'object' && 'quote' in quote) {
            delete quote.quote;
        }
        let content = '';
        if (quote === null) {
            return React.createElement(React.Fragment, null);
        }
        if (typeof quote === 'object') {
            content = (React.createElement("div", null,
                React.createElement(MessageItemPreview, { type: quote?.type, meta: quote?.meta }),
                React.createElement("span", null, quote.text)));
        }
        if (typeof quote === 'string') {
            content = quote;
        }
        return (React.createElement("div", { className: "relative" },
            React.createElement("div", { className: "absolute top-[4px] left-0" },
                React.createElement(ReplyIcon, { width: 16, height: 16 })),
            React.createElement("div", { className: "w-full pl-6" },
                React.createElement("div", { className: "break-words" }, content))));
    };
    const renderPreview = useMemo(() => {
        if (value !== null && typeof value === 'object') {
            return (React.createElement("div", { className: "relative pr-12 pl-3 py-2" },
                React.createElement("button", { type: "button", onClick: handleRemovePreview, className: "absolute top-3 right-3" },
                    React.createElement(XIcon, { height: 20, width: 20 })),
                value.quote ? (renderQuote(value.quote)) : (React.createElement(MessageItemPreview, { type: value?.type, meta: value?.meta }))));
        }
        return React.createElement(React.Fragment, null);
    }, [value]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "chat-compose-message" },
            React.createElement("div", { className: "p-3 flex flex-col gap-2" },
                renderPreview,
                suggestion?.length > 0 && (React.createElement("div", { className: "px-3 flex flex-row gap-3 overflow-x-auto overflow-y-hidden no-scrollbar whitespace-nowrap" }, suggestion?.map((item, idx) => (React.createElement("div", { key: `suggestion-${idx}`, className: "px-2 py-1 border-[1px] border-theme-primary-main rounded-xl cursor-pointer", onClick: handleClickSuggestion },
                    React.createElement("p", { className: "max-w-[13ch] overflow-hidden whitespace-nowrap text-ellipsis text-xs text-theme-primary-main" }, item)))))),
                showedEmoji && (React.createElement(EmojiPicker, { searchDisabled: true, onEmojiClick: handleClickEmoji, autoFocusSearch: false, emojiStyle: EmojiStyle.NATIVE, height: 430, width: 320, lazyLoadEmojis: true })),
                React.createElement("div", { className: "flex gap-2 items-end" },
                    React.createElement("span", { className: "cursor-pointer pb-2", onClick: () => setShowedEmoji(!showedEmoji) },
                        React.createElement(EmojiHappyIcon, { height: 24, width: 24, color: "gray" })),
                    React.createElement(Input.TextArea, { autoSize: { minRows: 1, maxRows: 5 }, value: renderedValue, onChange: handleChange, className: "flex-auto", placeholder: "Tulis Pesan", onKeyDown: handleKeyDown }),
                    React.createElement(Button, { htmlType: "button", variant: "primary", size: "large", className: "flex items-center justify-center h-full", style: { paddingLeft: 10, paddingRight: 10 }, onClick: handleSend, loading: isLoadingSend, disabled: renderedValue.length <= 0 },
                        React.createElement(SendIcon, null))),
                React.createElement("div", { className: "pl-8 text-gray-400", style: { fontSize: 12 } },
                    MAX_CHARACTERS - renderedValue.length,
                    " karakter tersisa")))));
};
export default ComposeMessage;
