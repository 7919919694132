import { Upload } from 'antd';
import React from 'react';
import Button from '../Button/Button';
import Text from '../Text/Text';
const UploadButton = ({ variant = 'primary', label = 'Pilih file', showUploadList = false, multiple = false, maxCount = 1, maxSize = 5, onError = () => { }, onChange = () => { }, loading = false, remarks, ...restUploadProps }) => {
    const handleChange = (event) => {
        const { fileList } = event;
        const notValidatedFileSize = fileList.find((item) => {
            const { originFileObj: file } = item;
            const { size } = file;
            if (size > maxSize * 1024 * 1024) {
                return true;
            }
            return false;
        });
        if (notValidatedFileSize) {
            return onError({ message: `ukuran file melebihi ${maxSize} MB` });
        }
        return onChange(event);
    };
    return (React.createElement(Upload, { showUploadList: showUploadList, maxCount: maxCount, multiple: multiple, onChange: handleChange, className: "flex justify-center", ...restUploadProps },
        React.createElement("div", { className: "flex flex-col gap-2 items-center" },
            React.createElement(Button, { variant: variant, loading: loading }, label),
            remarks ? React.createElement(Text, { className: "text-xs text-center" }, remarks) : '')));
};
export default UploadButton;
