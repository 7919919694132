import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_ZOOM } from './constants';
import CenterMarkerAbsolute from './CenterMarkerAbsolute';
import LocationLabel from './LocationLabel';
const LocationViewer = ({ width, height, geoloc }) => {
    const refMapContainer = useRef();
    const map = useRef();
    const initMap = () => {
        map.current = new window.google.maps.Map(refMapContainer.current, {
            center: geoloc,
            zoom: DEFAULT_ZOOM,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            draggable: false,
            minZoom: 10,
            maxZoom: 18,
            zoomControl: false,
        });
    };
    useEffect(() => {
        initMap();
        return () => { };
    }, []);
    return (React.createElement("div", { className: "rounded-lg border border-gray-200 overflow-hidden" },
        React.createElement("div", { style: { width, height, position: 'relative' } },
            React.createElement("div", { ref: refMapContainer, id: "map", style: { width: '100%', height: '100%' } }),
            React.createElement(CenterMarkerAbsolute, null)),
        React.createElement("div", { className: "p-3" },
            React.createElement(LocationLabel, { geoloc: geoloc, isLoading: false }))));
};
LocationViewer.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    geoloc: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }).isRequired,
};
LocationViewer.defaultProps = {
    width: '100%',
    height: '100%',
};
export default LocationViewer;
