import IconInstagram from './IconInstagram';
import IconTiktok from './IconTiktok';
import IconFacebookCircle from './IconFacebookCircle';
import IconLinkedin from './IconLinkedin';
import IconTwitterX from './IconTwitterX';
import IconYoutube from './IconYoutube';
const IconSVG = {
    IconInstagram: IconInstagram,
    IconTiktok: IconTiktok,
    IconLinkedin: IconLinkedin,
    IconFacebookCircle: IconFacebookCircle,
    IconTwitterX: IconTwitterX,
    IconYoutube: IconYoutube,
};
export default IconSVG;
