import clsx from 'clsx';
import React from 'react';
const BadgeCounter = ({ count = 0, overflowCount = 99, style = {}, }) => (React.createElement(React.Fragment, null, count > 0 && (React.createElement("div", { className: clsx([
        'bg-theme-primary-main rounded-full px-2 py-1 text-white text-xs font-light tracking-wide flex items-center justify-center',
    ]), style: {
        width: 25,
        height: 25,
        ...style,
    } }, count >= overflowCount ? 99 : count))));
export default BadgeCounter;
