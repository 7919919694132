import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
export const currencyFormatter = (value, allowedDecimal = 2) => {
    if (!value) {
        return '0';
    }
    const decimalCount = Number(value) % 1 == 0 ? 0 : allowedDecimal;
    const valueString = Number(value).toFixed(decimalCount);
    return valueString
        .toString()
        .replace('.', ',')
        .replace(/^0+/, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        .replace(/[A-Za-z]/g, '');
};
const BillSummary = ({ data = [], total }) => {
    const [collapsedItems, setCollapsedItems] = useState([]);
    const handleToggleCollapse = (index) => {
        if (!collapsedItems.find((item) => item === index)) {
            return setCollapsedItems((state) => [...state, index]);
        }
        return setCollapsedItems((state) => state.filter((item) => item !== index));
    };
    const isCollapse = (index) => {
        const condition = !!collapsedItems.find((item) => item === index);
        return condition;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { gutter: [4, 10] },
            data.length > 0 &&
                data.map((item, index) => {
                    return (React.createElement(Col, { key: index, span: 24 },
                        React.createElement("div", { className: "grid grid-cols-2 gap-1" },
                            React.createElement("button", { className: "text-grayScale04 text-left flex gap-1 items-center cursor-pointer", onClick: () => handleToggleCollapse(index) },
                                (item.childs || []).length > 0 && (React.createElement("span", null, isCollapse(index) ? (React.createElement(ChevronUpIcon, { width: 16, height: 16, color: "black" })) : (React.createElement(ChevronDownIcon, { width: 16, height: 16, color: "black" })))),
                                React.createElement("div", null, item.title)),
                            React.createElement("span", { className: "text-grayScale05 font-semibold text-right" },
                                React.createElement("div", null, item.type === 'subtract'
                                    ? `(Rp${currencyFormatter(item.value)})`
                                    : item.type === 'string'
                                        ? `${item?.value}`
                                        : `Rp${currencyFormatter(item.value)}`))),
                        React.createElement("div", { className: "pl-[20px]", style: { display: isCollapse(index) ? 'block' : 'none' } }, (item.childs || []).length > 0 &&
                            item.childs.map((childItem) => (React.createElement("div", { className: "grid grid-cols-2 gap-1" },
                                React.createElement("span", { className: "text-grayScale04 text-left flex gap-1 items-center" },
                                    React.createElement("div", null, childItem.title)),
                                React.createElement("span", { className: "text-grayScale05 font-semibold text-right" },
                                    React.createElement("div", null, childItem.type === 'subtract'
                                        ? `(Rp${currencyFormatter(childItem.value)})`
                                        : childItem.type === 'string'
                                            ? `${childItem?.value}`
                                            : `Rp${currencyFormatter(childItem.value)}`))))))));
                }),
            React.createElement(Col, { span: 24, className: "grid grid-cols-2 gap-1" },
                React.createElement("span", { className: "text-grayScale04 text-left font-bold" }, "Total"),
                React.createElement("span", { className: "text-theme-primary-main font-bold text-right" },
                    "Rp",
                    currencyFormatter(total !== null && total >= 0
                        ? total
                        : data.reduce((a, b) => {
                            if (typeof b.value === 'number') {
                                if (b.type === 'subtract') {
                                    return a - b.value;
                                }
                                return a + b.value;
                            }
                            else {
                                return a;
                            }
                        }, 0)))))));
};
export default BillSummary;
