import React from 'react';
import { Wrapper as GoogleMapReactWrapper } from '@googlemaps/react-wrapper';
import PropTypes from 'prop-types';
const Wrapper = ({ children, libraries, apiKey }) => {
    return (React.createElement(GoogleMapReactWrapper, { apiKey: apiKey, libraries: libraries }, children));
};
Wrapper.propTypes = {
    apiKey: PropTypes.string.isRequired,
    libraries: PropTypes.arrayOf(PropTypes.string),
};
Wrapper.defaultProps = {
    libraries: [],
};
export default Wrapper;
