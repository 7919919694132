import Button from '../Button';
import Modal from '../Modal';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import CenterMarkerAbsolute from './CenterMarkerAbsolute';
import { LocationMarkerIcon, SearchIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { DEFAULT_CENTER, DEFAULT_ZOOM } from './constants';
import LocationLabel from './LocationLabel';
const LocationPicker = (props) => {
    const { height, width, initialGeoloc, onChange } = props;
    const refMapContainer = useRef();
    const map = useRef();
    const autocompleteRef = useRef();
    const inputSearch = useRef();
    const [showedModalSearchPlace, setShowedModalSearchPlace] = useState(false);
    const [centerPosition, setCenterPosition] = useState(initialGeoloc);
    const [isDragging, setIsDragging] = useState(false);
    // Map event listener
    const handleDrag = (event) => {
        setIsDragging(true);
    };
    const handleIdle = () => {
        setCenterPosition({
            lat: map.current.center.lat(),
            lng: map.current.center.lng(),
        });
        setIsDragging(false);
    };
    const handlePlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        if (place) {
            if (place.geometry?.location) {
                const { lat, lng } = place.geometry.location;
                map.current.setCenter({
                    lat: lat(),
                    lng: lng(),
                });
                map.current.setZoom(DEFAULT_ZOOM);
            }
        }
        inputSearch.current.value = '';
        setShowedModalSearchPlace(false);
    };
    const initMap = () => {
        map.current = new window.google.maps.Map(refMapContainer.current, {
            center: centerPosition,
            zoom: DEFAULT_ZOOM,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            draggable: true,
            minZoom: 10,
            maxZoom: 25,
        });
    };
    const addListenerToMap = () => {
        window.google.maps.event.addListener(map.current, 'idle', handleIdle);
        window.google.maps.event.addListener(map.current, 'drag', handleDrag);
    };
    const initAutoComplete = () => {
        autocompleteRef.current = new window.google.maps.places.Autocomplete(inputSearch.current, {
            fields: ['formatted_address', 'geometry', 'name'],
            language: 'id',
            // strictBounds: false,
            // types: ['establishment'],
        });
        autocompleteRef.current.bindTo('bounds', map.current);
        autocompleteRef.current.addListener('place_changed', handlePlaceChanged);
    };
    const getCurrentLocation = async () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(async function (position) {
                await map.current.setCenter({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
                await map.current.setZoom(DEFAULT_ZOOM);
                setCenterPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            });
        }
    };
    const handleChooseLocation = () => {
        onChange(centerPosition);
    };
    useEffect(() => {
        initMap();
        addListenerToMap();
        return () => { };
    }, []);
    useEffect(() => {
        if (showedModalSearchPlace) {
            inputSearch.current?.focus();
        }
    }, [showedModalSearchPlace]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "rounded-lg border border-gray-200 overflow-hidden" },
            React.createElement("div", { style: { width, height, position: 'relative' } },
                React.createElement("div", { ref: refMapContainer, id: "map", style: { width: '100%', height: '100%' } }),
                React.createElement(CenterMarkerAbsolute, { isDragging: isDragging }),
                React.createElement("div", { className: "flex flex-wrap gap-2 absolute top-0 left-0 right-0 w-full p-3" },
                    React.createElement(Button, { variant: "outlined", size: "large", className: "bg-white", onClick: getCurrentLocation },
                        React.createElement("div", { className: "flex gap-2 items-center" },
                            React.createElement(LocationMarkerIcon, { width: 16, height: 16 }),
                            React.createElement("span", null, "Gunakan Lokasi Saat Ini"))),
                    React.createElement(Button, { variant: "outlined", size: "large", className: "bg-white", onClick: () => setShowedModalSearchPlace(true) },
                        React.createElement("div", { className: "flex gap-2 items-center" },
                            React.createElement(SearchIcon, { width: 16, height: 16 }),
                            React.createElement("span", null, "Cari Ulang Alamat"))))),
            React.createElement("div", { className: "p-3" },
                React.createElement(LocationLabel, { geoloc: centerPosition, isLoading: isDragging }))),
        React.createElement("div", { className: "flex justify-center mt-2" },
            React.createElement(Button, { variant: "primary", onClick: handleChooseLocation, loading: isDragging, disabled: isDragging, size: "large" }, "Pilih Lokasi Ini")),
        React.createElement(Modal, { width: 820, title: "Cari Lokasi", open: showedModalSearchPlace, onCancel: () => setShowedModalSearchPlace(false), footer: null },
            React.createElement("div", { className: "min-h-[420px]" },
                React.createElement("input", { ref: inputSearch, style: { width: '100%' }, className: "border border-gray-400 rounded-lg py-2 px-4  outline-theme-primary-main", placeholder: "cari", onChange: (event) => {
                        const { value } = event.target;
                        if (value.length < 3) {
                            return event.stopPropagation();
                        }
                        if (!autocompleteRef.current) {
                            initAutoComplete();
                        }
                    } })))));
};
LocationPicker.propTypes = {
    onChange: PropTypes.func,
    initialGeoloc: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
LocationPicker.defaultProps = {
    onChange: () => { },
    initialGeoloc: DEFAULT_CENTER,
    width: '100%',
    height: '100%',
};
export default LocationPicker;
