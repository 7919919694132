import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
export const LocationLabel = ({ geoloc, isLoading }) => {
    const queryGeolocation = useQuery(['get-location-name-from-geoloc', geoloc], async () => {
        try {
            const geocoder = new window.google.maps.Geocoder();
            const response = await geocoder.geocode({
                location: geoloc,
            });
            return response.results;
        }
        catch (error) {
            console.error('error', error);
            return Promise.reject(error);
        }
    }, {
        enabled: geoloc.lat !== 0 && geoloc.lng !== 0,
    });
    const centerPositionAddressLabel = useMemo(() => {
        const { data, isLoading } = queryGeolocation;
        if (!data && isLoading) {
            return null;
        }
        if (data?.length > 0) {
            const firstData = data[0];
            let title = '';
            let subTitles = [];
            const { address_components, formatted_address } = firstData;
            if (address_components.length > 0) {
                address_components.forEach((item, index) => {
                    if (![
                        'route',
                        'plus_code',
                        'street_number',
                        'subpremise',
                        'administrative_area_level_7',
                        'administrative_area_level_6',
                    ].includes(item.types[0])) {
                        if (!title) {
                            title = item.long_name;
                        }
                        else {
                            subTitles.push(item.short_name);
                        }
                    }
                });
            }
            return (React.createElement("div", null, title ? (React.createElement("div", null,
                React.createElement("p", { className: "font-semibold" }, title),
                React.createElement("p", { className: "text-sm" }, subTitles.join(', ')))) : (React.createElement("p", null, formatted_address))));
        }
    }, [queryGeolocation.data, queryGeolocation.isLoading]);
    return (React.createElement("div", { className: "bg-white h-[50px]" }, (queryGeolocation.isLoading &&
        queryGeolocation.isRefetching &&
        queryGeolocation.isFetching) ||
        isLoading ? (React.createElement("div", { className: "grid grid-col-1" },
        React.createElement(Skeleton, { count: 1, height: 14, width: 120 }),
        React.createElement(Skeleton, { count: 1, height: 14, width: 250 }))) : (centerPositionAddressLabel)));
};
LocationLabel.propTypes = {
    isLoading: PropTypes.bool,
    geoloc: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }).isRequired,
};
LocationLabel.defaultProps = {
    isLoading: false,
};
export default LocationLabel;
