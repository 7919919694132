import { TrashIcon } from '@heroicons/react/solid';
import { Col, Row } from 'antd';
import prettyBytes from 'pretty-bytes';
import React, { useEffect, useState } from 'react';
const ItemRender = ({ fileObject, action }) => {
    const [fileSize, setFileSize] = useState(0);
    const { size, url } = fileObject;
    const getFileSizeFromUrl = async (url) => {
        fetch(url)
            .then(async (r) => ({
            size: (await r.blob())?.size || 0,
        }))
            .then(({ size }) => {
            setFileSize(size);
        })
            .catch((error) => {
            console.error(error);
        });
    };
    useEffect(() => {
        if (url && size <= 0) {
            getFileSizeFromUrl(url);
        }
        else {
            setFileSize(size);
        }
    }, [url, size]);
    const isImage = (fileObject?.type ?? '').startsWith('image/');
    return (React.createElement(Row, { gutter: [0, 0] },
        React.createElement(Col, { span: 4, style: { height: 100 } }, isImage && (React.createElement("div", { className: "w-full h-full rounded-lg bg-gray-200 overflow-hidden" },
            React.createElement("img", { src: fileObject.url, className: "w-full h-full object-cover object-center", alt: "" })))),
        React.createElement(Col, { span: 14 },
            React.createElement("div", { className: "flex-auto p-3" },
                React.createElement("div", { className: "font-semibold w-full break-words text-ellipsis truncate", title: fileObject.name }, fileObject.name),
                React.createElement("div", null, prettyBytes(fileSize)))),
        React.createElement(Col, { span: 2, className: "" },
            React.createElement("div", { className: "pt-3 flex justify-end" },
                React.createElement("button", { className: "fill-red-500", onClick: () => action.remove() },
                    React.createElement(TrashIcon, { width: 24, height: 24, color: "inherit", className: "fill-red-600" }))))));
};
export default ItemRender;
