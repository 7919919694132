import React, { useMemo, useState } from 'react';
import { Input } from 'antd';
import numeral from 'numeral';
export const regexDecimal = /\d+\.?\d*/g; // Decimal, dot are allowed
const InputPrice = ({ value, onChange, type, ...props }) => {
    const [isPriceInitialized, setIsPriceInitialized] = useState(false);
    const handleChange = (event) => {
        const { value } = event.target;
        if (value !== '' && !value.match(regexDecimal))
            return;
        const currentTarget = event.target.cloneNode(true);
        const rawValue = event.target.value;
        const unformattedValue = rawValue.replace(/[^\d,]/g, '');
        let formattedValue = value !== '' ? numeral(rawValue).value() : value;
        if (type === 'decimal' && !Number.isNaN(parseFloat(unformattedValue))) {
            const [integerPart, decimalPart] = `${unformattedValue}`.split(',');
            if (decimalPart || decimalPart === '') {
                const shortenedDecimalPart = decimalPart.slice(0, 10); // limit decimal part to precision of 10
                const formattedFinalValue = `${integerPart}.${shortenedDecimalPart}`;
                formattedValue = formattedFinalValue;
            }
            else {
                formattedValue = integerPart;
            }
        }
        const newEvent = {
            ...event,
            target: { ...event.target, value: formattedValue },
            currentTarget: { value: currentTarget },
        };
        if (onChange !== undefined)
            onChange(newEvent);
    };
    const displayedValue = useMemo(() => {
        if (value === '')
            return '';
        if (type === 'decimal' && !Number.isNaN(parseFloat(String(value)))) {
            const [integerPart, decimalPart] = `${value}`.split('.');
            const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // add . as a thousand separator
            let formattedDecimalpart = decimalPart;
            if (!isPriceInitialized) {
                setIsPriceInitialized(true);
                formattedDecimalpart = decimalPart?.replace(/0+$/, ''); // remove trailing zeros
                if (!formattedDecimalpart)
                    return formattedIntegerPart;
            }
            if (formattedDecimalpart || formattedDecimalpart === '') {
                const shortenedDecimalPart = formattedDecimalpart.slice(0, 10); // limit decimal part to precision of 10
                const formattedFinalValue = `${formattedIntegerPart},${shortenedDecimalPart}`;
                return formattedFinalValue;
            }
            return formattedIntegerPart;
        }
        if (String(value).match(regexDecimal))
            return numeral(value).format('0,0');
        return '';
    }, [value]);
    return React.createElement(Input, { value: displayedValue, type: "text", onChange: handleChange, ...props });
};
export default InputPrice;
