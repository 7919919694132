import React, { useEffect } from 'react';
import Button from '../Button/Button';
import { PrinterFilled } from '@ant-design/icons';
const PrintableDocument = ({ children, landscape = false, hidePrintButton = false }) => {
    const handlePrint = () => {
        if (landscape) {
            var css = '@page { size: landscape; }', head = document.head || document.getElementsByTagName('head')[0], style = document.createElement('style');
            style.type = 'text/css';
            style.media = 'print';
            style.appendChild(document.createTextNode(css));
            head.appendChild(style);
            window.print();
        }
        else {
            window.print();
        }
    };
    // Handle CTRL + P
    useEffect(() => {
        const handleKeyDown = (e) => {
            // Check if Ctrl + P is pressed
            if ((e.ctrlKey || e.metaKey) && e.key === 'p') {
                e.preventDefault(); // Prevent default browser behavior (printing)
                // Handle custom print
                if (!hidePrintButton) {
                    handlePrint();
                }
            }
        };
        // Attach event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);
        // Detach event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [hidePrintButton]);
    // Handle disable right click
    useEffect(() => {
        const handleRightClick = (e) => {
            if (hidePrintButton) {
                e.preventDefault();
            }
        };
        // Attach event listener when component mounts
        document.addEventListener('contextmenu', handleRightClick);
        // Detach event listener when component unmounts
        return () => {
            document.removeEventListener('contextmenu', handleRightClick);
        };
    }, [hidePrintButton]);
    return (React.createElement("div", { className: hidePrintButton ? 'print:hidden' : '' },
        React.createElement("div", { className: "bg-white fixed w-screen h-[50px] shadow-md flex items-center justify-end pr-10 print:hidden z-[100]" }, !hidePrintButton && (React.createElement(Button, { variant: "primary", onClick: handlePrint, icon: React.createElement(PrinterFilled, null), size: "large" }, "Unduh/Cetak"))),
        React.createElement("div", { className: "pt-[50px] print:pt-0 w-full md:max-w-screen-md lg:max-w-screen-lg mx-auto relative", style: { WebkitPrintColorAdjust: 'exact', colorAdjust: 'exact' } }, children)));
};
export default PrintableDocument;
