import clsx from 'clsx';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { MESSAGE_TYPE } from './Chat.interfaces';
import { isJsonString } from '../utils/object.utils';
import MessageItemPreview from './MessageItemPreview';
import { DotsVerticalIcon, ReplyIcon } from '@heroicons/react/solid';
import { Dropdown } from 'antd';
import { useHover } from 'usehooks-ts';
import 'moment/locale/id';
const MessageItem = ({ id = '', value = '', time = null, isMe = false, status, onClickPreview = () => { }, onReply = () => { }, }) => {
    const isValueJsonString = useMemo(() => isJsonString(value), [value]);
    const text = useMemo(() => {
        if (isValueJsonString) {
            return JSON.parse(value)?.text || '';
        }
        return value;
    }, []);
    const textObject = useMemo(() => {
        if (isValueJsonString) {
            const object = JSON.parse(value);
            return object;
        }
        return null;
    }, [value]);
    const allowedTypesRenderPreviewItem = [MESSAGE_TYPE.PRODUCT_RETAIL, MESSAGE_TYPE.PO];
    const [showMenuChat, setShowMenuChat] = useState(false);
    const ref = useRef();
    const hovering = useHover(ref);
    // const hovering = true;
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const handleOpenChange = useCallback((open) => {
        setIsOpenDropdown(open);
    }, []);
    const handleClickReply = () => {
        setIsOpenDropdown(false);
        if (textObject) {
            return onReply(textObject);
        }
        else {
            return onReply(text);
        }
    };
    const handleClickQuote = () => { };
    const renderQuote = (quote) => {
        if (quote !== null && typeof quote === 'object' && 'quote' in quote) {
            delete quote.quote;
        }
        let content = '';
        if (quote === null) {
            return React.createElement(React.Fragment, null);
        }
        if (typeof quote === 'object') {
            content = (React.createElement("div", null,
                React.createElement(MessageItemPreview, { type: quote?.type, meta: quote?.meta, onClick: onClickPreview }),
                React.createElement("span", { className: "" }, quote.text)));
        }
        if (typeof quote === 'string') {
            content = quote;
        }
        return (React.createElement("div", { className: "opacity-70 mb-4 relative", onClick: handleClickQuote },
            React.createElement("div", { className: "absolute top-[4px] left-0" },
                React.createElement(ReplyIcon, { width: 16, height: 16 })),
            React.createElement("div", { className: "w-full pl-6" },
                React.createElement("div", { className: "break-words" }, content))));
    };
    return (React.createElement("div", { className: "flex flex-col gap-2" },
        !!textObject && allowedTypesRenderPreviewItem.includes(textObject?.type ?? '') && (React.createElement("div", { className: "bg-white px-3 py-2 border rounded-xl" },
            React.createElement(MessageItemPreview, { onClick: onClickPreview, type: textObject.type, meta: textObject.meta }))),
        React.createElement("div", { className: clsx([isMe ? 'text-right' : 'text-left']) },
            React.createElement("div", { ref: ref, className: clsx([
                    'min-h-[29px] min-w-[40px] max-w-[85%] break-words',
                    'inline-block',
                    'px-3 py-2',
                    isMe ? 'bg-theme-primary-main bg-opacity-30' : 'bg-gray-200',
                    isMe ? 'rounded-tr-none' : 'rounded-tl-none',
                    'rounded-xl',
                    'text-gray-900',
                    // 'relative',
                ]) },
                !!textObject && textObject.quote && renderQuote(textObject.quote),
                React.createElement("div", { className: "relative pr-[12px]" },
                    (hovering || isOpenDropdown) && (React.createElement(Dropdown, { open: isOpenDropdown, onOpenChange: handleOpenChange, placement: "bottomLeft", trigger: ['click'], menu: {
                            items: [
                                {
                                    key: MESSAGE_TYPE.REPLY,
                                    label: (React.createElement("div", { className: "flex gap-2 items-center" },
                                        React.createElement(ReplyIcon, { width: 14, height: 14, className: "mb-1" }),
                                        React.createElement("span", null, "Balas"))),
                                    onClick: handleClickReply,
                                },
                            ],
                        } },
                        React.createElement("span", { className: "absolute -right-[8px] top-0 block cursor-pointer" },
                            React.createElement("div", { className: "" },
                                React.createElement(DotsVerticalIcon, { height: 18, width: 18, color: "#4b4b4b4" }))))),
                    React.createElement("span", { className: "" }, text),
                    React.createElement("div", { className: clsx([
                            'flex gap-2 pt-1 items-center',
                            isMe ? 'justify-end' : 'justify-start',
                        ]) },
                        !!time && (React.createElement("div", { className: "text-gray-900 opacity-50 tracking-wide", style: { fontSize: 10 } }, time)),
                        isMe && (React.createElement("svg", { width: "14", height: "8", viewBox: "0 0 14 8", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                            React.createElement("path", { d: "M4.33333 4.32409L7.162 7.15276L12.818 1.49609M1 4.32409L3.82867 7.15276M9.48533 1.49609L7.33333 3.66743", stroke: status === 'read' ? '#00A925' : '#959595', strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })))))))));
};
export default MessageItem;
